<template>
  <div class="flex flex-col justify-center h-full min-h-screen bg-white">
    <div class="min-h-full md:py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-lg lg:max-w-sm">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            class="h-16 mx-auto w-15"
            src="/colonia_logo_icon.svg"
            alt="Colonia Technologies"
          />
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
